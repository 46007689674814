import React, { useEffect } from "react";
import { graphql } from "gatsby";

import Root from "../components/Root";
import Meta from "../components/Meta";
import Blades from "../components/Blades";

export const query = graphql`
  query getPage($id: String) {
    datoCmsPage(id: { eq: $id }) {
      seo {
        description
        title
        twitterCard
        image {
          url
        }
      }
      title
      headerColour
      blades {
        ... on DatoCmsVideoHero {
          ...VideoHero
        }
        ... on DatoCmsVideoWithText {
          ...VideoWithText
        }
        ... on DatoCmsFeaturedGame {
          ...FeaturedGame
        }
        ... on DatoCmsGamesCarousel {
          ...GamesCarousel
        }
        ... on DatoCmsLatestNews {
          ...LatestNews
        }
        ... on DatoCmsCareersSpotlight {
          ...CareersSpotlight
        }
        ... on DatoCmsCareersArchive {
          ...CareersArchive
        }
        ... on DatoCmsImageHero {
          ...ImageHero
        }
        ... on DatoCmsCardsWithAnimation {
          ...CardsWithAnimation
        }
        ... on DatoCmsImageWithText {
          ...ImageWithText
        }
        ... on DatoCmsStudioShowcase {
          ...StudioShowcase
        }
        ... on DatoCmsLogoGrid {
          ...LogoGrid
        }
        ... on DatoCmsAwardsShowcase {
          ...AwardsShowcase
        }
        ... on DatoCmsFeatured {
          ...Featured
        }
        ... on DatoCmsGamesGrid {
          ...GamesGrid
        }
        ... on DatoCmsPreviousReleasesCarousel {
          ...PreviousReleasesCarousel
        }
        ... on DatoCmsPostSearchBar {
          ...PostSearchBar
        }
        ... on DatoCmsPostsGrid {
          ...PostsGrid
        }
        ... on DatoCmsStudioPerksList {
          ...StudioPerksList
        }
        ... on DatoCmsModalGrid {
          ...ModalGrid
        }
        ... on DatoCmsFeaturesPanel {
          ...FeaturesPanel
        }
        ... on DatoCmsMediaShowcase {
          ...MediaShowcase
        }
        ... on DatoCmsFlexibleWorking {
          ...FlexibleWorking
        }
        ... on DatoCmsInvestingInCareer {
          ...Investing
        }
        ... on DatoCmsPerksAndBenefit {
          ...PerksAndBenefits
        }
        ... on DatoCmsSupportWeOffer {
          ...SupportWeOffer
        }
        ... on DatoCmsWhatWereLookingFor {
          ...Partner
        }
        ... on DatoCmsWorldsBest {
          ...WorldsBest
        }
        ... on DatoCmsEditor {
          ...Editor
        }
        ... on DatoCmsAcademyHero {
          ...AcademyHero
        }
        ... on DatoCmsAcademyHowItWork {
          ...AcademyHowItWorks
        }
        ... on DatoCmsAcademyCareersArchive {
          ...AcademyCareersArchive
        }
        ... on DatoCmsVideo {
          ...Video
        }
        ... on DatoCmsAcademySlider {
          ...AcademySlider
        }
        ... on DatoCmsPressReleaseBlock {
          ...PressReleases
        }
        ... on DatoCmsPublishingWhatWeOfferBlock {
          ...PublishingWhatWeOffer
        }
        ... on DatoCmsPublishingTestimonialsBlock {
          ...PublishingTestimonials
        }
        ... on DatoCmsPublishingProcessBlock {
          ...PublishingProcess
        }
        ... on DatoCmsPublishingPitchFormBlock {
          ...PublishingPitchForm
        }
      }
    }
  }
`;
const PageTemplate = ({ data }) => {
  const page = data?.datoCmsPage;
  const seo = page?.seo;
  const blades = page?.blades;

  return (
    <Root headerColour={page?.headerColour}>
      <Meta {...{ seo }} />
      <Blades {...{ blades }} />
    </Root>
  );
};

export default PageTemplate;
